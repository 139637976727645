import React from 'react'
import { Link } from "gatsby"
import Img from 'gatsby-image'
import { graphql } from "gatsby"
import { Container, Row, Col, Button } from 'reactstrap'
import Layout from '../components/Layout'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Helmet from "react-helmet"

const AccommodationPage = ({ data }) => (
  <Layout>
    <HelmetDatoCms seo={data.allDatoCmsFacilitiesPage.seoMetaTags} />
    <Helmet>
            <title>Garden Burees Facilities in Byron Bay</title>
            <meta name="description" content="Check out all the facilities we have to offer at the Garden Burees Byron Bay" />
        </Helmet>
    <div>
      <Container className="narrow">
        <Row>
          <Col>
          <h1>Our facilities</h1>
            {data.allDatoCmsFacilitiesPage.edges.map(({ node }, index) => (
              <div key={index}>
                <Img fluid={node.headerImage.fluid} />
                <h3 className="mt-3"> {node.mainHeading}</h3>

                <p>
                  <Link to={`/facilities/${node.slug}`}>
                    <Button outline color="primary">
                      Learn more
                    </Button>
                  </Link>
                </p>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default AccommodationPage

export const query = graphql`
  query FacilPageQuery {
    allDatoCmsFacilitiesPage {
      edges {
        node {
          mainHeading
          slug
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
          headerImage {
            fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
